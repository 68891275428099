import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import { ConfirmationDialog } from '@redislabsdev/redislabs-ui-components/ui/components/ConfirmationDialog';
import { StoreInterface } from '../../../interfaces/storeInterfaces';
import {
  updateAccountMarketplaceStatus,
  getAccountMarketplaceDetails,
} from './AccountMarketplaceDetails.api';

const AccountMarketplaceStatusUpdateModal: React.FC = () => {
  const dispatch = useDispatch();
  const { id: marketplaceId } = useParams<{ id: string }>();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const marketplace = searchParams.get('marketplace') as string;
  const productCode = searchParams.get('productCode') as string;
  const modalValues = useSelector(
    (state: StoreInterface) => state.accountsMarketplacesPage.accountMarketplaceStatusUpdateModal
  );

  const onSubmit = async () => {
    await updateAccountMarketplaceStatus(modalValues, dispatch);
    await getAccountMarketplaceDetails(marketplaceId, marketplace, productCode, dispatch);
  };

  return (
    <ConfirmationDialog
      title="Confirm MP subscription status change"
      isOpen={modalValues.showModal}
      cancelButtonLabel="Cancel"
      submitButtonLabel="Confirm"
      modalContentStyle={{ width: '51rem' }}
      handleModalCancel={() => {
        dispatch({ type: 'resetAccountMarketplaceStatusUpdateModalValues' });
      }}
      handleModalSubmit={onSubmit}
    >
      Please confirm that you want to change your marketplace subscription status. Select 'Confirm'
      to proceed or 'Cancel' to return to the previous screen.
    </ConfirmationDialog>
  );
};

export default AccountMarketplaceStatusUpdateModal;
