import React from 'react';
import moment from 'moment';

import * as S from './AccountMarketplaceDetailsPage.styles';
import * as CS from '../../../styles/common.style';
import { SectionTitle } from '../../../styles/commonAccountDetails.style';
import { useSelector } from 'react-redux';
import { StoreInterface } from '../../../interfaces/storeInterfaces';
import { PROVIDERS } from '../../../constants/accountsMarketplacesPageConstants';

const AccountMarketplaceDetailsPageInfoSection: React.FC = () => {
  const marketplaceDetails = useSelector(
    (state: StoreInterface) => state.accountMarketplaceDetailsPage.accountMarketplaceDetails
  );
  const marketplaceProducts = useSelector(
    (state: StoreInterface) => state.accountsMarketplacesPage.marketplaceProducts
  );

  const {
    marketplace,
    marketplaceId,
    productCode,
    usageReportingId,
    entitlementId,
    entitlementState,
    entitlementActiveDate,
    entitlementOffer,
    awsAccountNumber,
    projectNumber,
    billingAccount,
    plan,
    is3P1C,
  } = marketplaceDetails;

  const productDisplayName =
    (marketplace &&
      marketplaceProducts[marketplace]?.find(
        ({ productCode }) => productCode === marketplaceDetails.productCode
      )?.displayName) ||
    'N/A';

  return (
    <S.InfoSection>
      <SectionTitle>Info</SectionTitle>
      <S.SectionContent>
        {/* Common details section */}
        <label htmlFor="marketplace">
          Marketplace
          <CS.ViewValues>{PROVIDERS.find(({ code }) => code === marketplace)?.label}</CS.ViewValues>
        </label>
        {marketplace === 'gcp' && (
          <label htmlFor="gcpType">
            GCP Marketplace Type <CS.ViewValues>{is3P1C ? '3P1C' : 'ISaaS'}</CS.ViewValues>
          </label>
        )}
        {marketplace === 'aws' && (
          <label htmlFor="awsAccountNumber">
            AWS Account Number
            <CS.ViewValues>{awsAccountNumber || 'N/A'}</CS.ViewValues>
          </label>
        )}
        <label htmlFor="marketplaceId">
          Customer Reference ID
          <CS.ViewValues>{marketplaceId}</CS.ViewValues>
        </label>
        <label htmlFor="productDisplayName">
          Product
          <CS.ViewValues>{productDisplayName}</CS.ViewValues>
        </label>
        <label htmlFor="productCode">
          Product Code (Dev use only)
          <CS.ViewValues>{productCode || 'N/A'}</CS.ViewValues>
        </label>
        <label htmlFor="reportingID">
          Reporting ID (Dev use only)
          <CS.ViewValues>{usageReportingId || 'N/A'}</CS.ViewValues>
        </label>
        {/* ISaaS-specific details */}
        {!is3P1C && marketplace != 'aws' && (
          <>
            <label htmlFor="entitlementName">
              Order ID (GCP ISaaS Only)
              <CS.ViewValues>{entitlementId || 'N/A'}</CS.ViewValues>
            </label>
            <label htmlFor="entitlementState">
              Entitlement State
              <CS.ViewValues>{entitlementState || 'N/A'}</CS.ViewValues>
            </label>
            <label htmlFor="entitlementActiveDate">
              Entitlement Active Date
              <CS.ViewValues>
                {entitlementActiveDate
                  ? moment(entitlementActiveDate).format('DD-MMM-YYYY')
                  : 'N/A'}
              </CS.ViewValues>
            </label>
            <label htmlFor="entitlementOffer">
              Private Offer ID (GCP ISaaS Only)
              <CS.ViewValues>{entitlementOffer || 'N/A'}</CS.ViewValues>
            </label>
          </>
        )}

        {/* 3P1C-specific details */}
        {is3P1C && (
          <>
            <label htmlFor="projectNumber">
              Project Number
              <CS.ViewValues>{projectNumber || 'N/A'}</CS.ViewValues>
            </label>
            <label htmlFor="billingAccount">
              External Account ID
              <CS.ViewValues>{billingAccount || 'N/A'}</CS.ViewValues>
            </label>
            <label htmlFor="plan">
              Plan
              <CS.ViewValues>{plan || 'N/A'}</CS.ViewValues>
            </label>
          </>
        )}
      </S.SectionContent>
    </S.InfoSection>
  );
};

export default AccountMarketplaceDetailsPageInfoSection;
