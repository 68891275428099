import { api, buildUrl } from '../../../api/config';
import {
  AccountMarketplaceDetails,
  GcpAccount,
  MarketplaceDetailsResponse,
  MultiAccountMarketplace,
} from '../../../interfaces/accountMarketplaceStore.type';
import { displayErrors } from '../../rootPage/RootPage.utils';

export async function getAccountMarketplaceDetails(
  marketplaceId: string,
  marketplace: string,
  productCode: string,
  dispatch
): Promise<void> {
  try {
    const response = await api.get(
      `${buildUrl(
        'accountsMarketplaces'
      )}/marketplacedetails/${marketplaceId}?marketplace=${marketplace}&productCode=${productCode}`
    );

    if (response.status === 200) {
      const marketplaceDetails = response.data as MarketplaceDetailsResponse;
      const multiAccountMarketplace = transformToMultiAccountMarketplace(marketplaceDetails);
      dispatch({ type: 'setAccountMarketplaceDetails', payload: multiAccountMarketplace });
    }

    dispatch({ type: 'setLoadingAccountMarketplaceDetails', payload: false });
  } catch (err) {
    displayErrors(err, 'Failed trying to get account marketplace details');
  }
}

export function transformToMultiAccountMarketplace(
  rawData: MarketplaceDetailsResponse
): MultiAccountMarketplace {
  if (!rawData || !rawData.marketplaceDetailsData || rawData.marketplaceDetailsData.length === 0) {
    throw new Error('Invalid or empty MarketplaceDetailsResponse data');
  }

  const awsAccountNumber = rawData.marketplaceDetailsData.find(
    ({ accountMarketplace }) => accountMarketplace.awsAccountNumber != null
  )?.accountMarketplace.awsAccountNumber;

  return rawData.marketplaceDetailsData.reduce<MultiAccountMarketplace>(
    (acc, detail) => {
      const { accountMarketplace, gcpAccount } = detail;

      if (accountMarketplace) {
        populateCommonDetails(acc, accountMarketplace);
        addSmAccount(acc.smAccounts, accountMarketplace);
      }

      if (gcpAccount) {
        populateGcpDetails(acc, gcpAccount);
        addSmAccountFromGcp(acc.smAccounts, gcpAccount);
      }

      return acc;
    },
    {
      marketplaceId: '',
      marketplace: '',
      smAccounts: [],
      productCode: rawData.productCode,
      awsAccountNumber,
      is3P1C: false, // Initialize as false; updated if GCP data is present
    } as MultiAccountMarketplace
  );
}

function populateCommonDetails(
  result: MultiAccountMarketplace,
  accountMarketplace: AccountMarketplaceDetails
): void {
  if (!result.marketplaceId) {
    const {
      marketplace,
      marketplaceId,
      entitlementId,
      entitlement,
      usageReportingId,
    } = accountMarketplace;

    result.marketplaceId = marketplaceId;
    result.marketplace = marketplace;
    result.entitlementId = entitlementId;
    result.entitlementState = entitlement?.state;
    result.entitlementName = entitlement?.name;
    result.entitlementActiveDate = entitlement?.activeDate;
    result.entitlementUpdateDate = entitlement?.updateDate;
    result.entitlementOffer = entitlement?.offer;
    result.entitlementOfferEndDate = entitlement?.offerEndDate;
    result.usageReportingId = usageReportingId;
  }
}

function populateGcpDetails(result: MultiAccountMarketplace, gcpAccount: GcpAccount): void {
  if (!result.is3P1C) {
    result.is3P1C = true; // Indicates this is a 3P1C type
    result.projectNumber = gcpAccount.projectNumber;
    result.billingAccount = gcpAccount.billingAccount;
    result.plan = gcpAccount.plan;
  }
}

function addSmAccount(
  smAccounts: MultiAccountMarketplace['smAccounts'],
  accountMarketplace: AccountMarketplaceDetails
): void {
  const {
    smAccountId,
    smAccountName,
    status,
    accountMarketplaceId,
    creationDate,
    lastUpdateDate,
  } = accountMarketplace;

  smAccounts.push({
    smAccountId,
    smAccountName,
    status,
    accountMarketplaceId,
    createdAt: creationDate,
    updatedAt: lastUpdateDate,
  });
}

function addSmAccountFromGcp(
  smAccounts: MultiAccountMarketplace['smAccounts'],
  gcpAccount: GcpAccount
): void {
  const { smAccountId, smAccountName, status } = gcpAccount;

  // Avoid duplicates
  if (!smAccounts.some((account) => account.smAccountId === smAccountId)) {
    smAccounts.push({
      smAccountId,
      smAccountName,
      status,
    });
  }
}

export async function addSmAccountMapping(
  marketplaceId: string,
  marketplace: string,
  productCode: string,
  smAccountId: number,
  dispatch
): Promise<void> {
  try {
    const response = await api.post(`${buildUrl('accountsMarketplaces')}/multiAccount`, {
      marketplaceId,
      smAccountId,
    });

    if (response.status === 200) {
      getAccountMarketplaceDetails(marketplaceId, marketplace, productCode, dispatch);
    }
  } catch (err) {
    displayErrors(err, 'Failed trying to add SM account mapping');
  }
}

export async function updateAccountMarketplaceStatus(reqParams, dispatch): Promise<void> {
  const { accountMarketplaceId, status } = reqParams;
  try {
    const response = await api.put(
      `${buildUrl('accountsMarketplaces')}/${accountMarketplaceId}/status`,
      { status }
    );
    if (response && response.status === 200) {
      dispatch({ type: 'resetAccountMarketplaceStatusUpdateModalValues' });
    }
  } catch (err) {
    displayErrors(err, 'There was a problem while trying to update account marketplace status');
  }
}
