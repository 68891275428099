import { Button, SvgIcon, Tooltip } from '@redislabsdev/redislabs-ui-components';
import { InfoIcon } from '@redislabsdev/redis-ui-icons';
import { theme } from '@redislabsdev/redis-ui-styles';
import { PROVIDERS } from '../../constants/accountsMarketplacesPageConstants';
import * as CS from '../../styles/common.style';
import * as S from './AccountsMarketplaces.styles';
import { ShowIcon } from '@redislabsdev/redis-ui-icons';
import {
  AccountMarketplaceStatus,
  MarketplaceProducts,
} from '../../interfaces/accountMarketplaceStore.type';

const getHeaderWithTooltip = (text: string, tooltipContent: string) => (
  <S.TableHeader>
    <S.TableHeaderText>{text}</S.TableHeaderText>
    <CS.TooltipWrapper>
      <Tooltip
        tooltipContent={tooltipContent}
        placement="top"
        maxWidth="27rem"
        trigger="hover"
        textColor={theme.semantic.color.text.neutral700}
      >
        <InfoIcon size="L" />
      </Tooltip>
    </CS.TooltipWrapper>
  </S.TableHeader>
);

const getCellWithTooltip = (value: string | number) =>
  value ? (
    <CS.TooltipWrapper>
      <Tooltip
        tooltipContent={<CS.TooltipTextWithTooltip>{value}</CS.TooltipTextWithTooltip>}
        placement="top"
        padding="1rem"
        trigger="hover"
        textColor={theme.semantic.color.text.neutral700}
      >
        <CS.TextWithTooltip>{value}</CS.TextWithTooltip>
      </Tooltip>
    </CS.TooltipWrapper>
  ) : (
    'N/A'
  );

const accountsMarketplacesColumnNames = [
  {
    header: 'Provider',
    accessor: 'provider',
    disableFilters: true,
    width: 5,
    Cell: ({ value }) => {
      const iconName = PROVIDERS.find(({ code }) => code === value)?.icon;
      return <SvgIcon width="20" height="20" name={iconName} />;
    },
  },
  {
    header: 'Product',
    accessor: 'productCode',
    disableFilters: true,
    width: 20,
  },
  {
    header: getHeaderWithTooltip(
      'Customer ref ID',
      'For AWS MP accounts, it is the unique identified associated with an AWS account number, also known as marketplace-id'
    ),
    accessor: 'customerRefId',
    disableFilters: true,
    width: 20,
    Cell: ({ value }) => getCellWithTooltip(value),
  },
  {
    id: 'smAccountID',
    header: 'SM Account ID',
    accessor: 'smAccounts',
    disableFilters: true,
    width: 10,
    Cell: ({ value }) => {
      return value.map(({ id }, index) => <S.TableCellGroupItem key={`${id}-${index}`}>{id}</S.TableCellGroupItem>);
    },
  },
  {
    id: 'mappingStatus',
    header: 'Status',
    accessor: 'smAccounts',
    disableFilters: true,
    width: 15,
  },
  {
    id: 'awsContractId',
    header: getHeaderWithTooltip('Contract ID', 'Applies to AWS MP annual only'),
    accessor: 'smAccounts',
    disableFilters: true,
    width: 10,
    Cell: ({ value }) => {
      const distinctContractIds = [...new Set(value.map(({ contractId }) => contractId).filter(Boolean))];
      return distinctContractIds.map((contractId, index) => (
        <S.TableCellGroupItem key={`${contractId}-${index}`}>{contractId}</S.TableCellGroupItem>
      ));
    },    
  },
  {
    header: 'Actions',
    id: 'actions',
    disableFilters: true,
    disableSortBy: true,
    width: 10,
  },
];

export const getAccountsMarketplacesColumns = (
  accountsMarketplacesStatuses: AccountMarketplaceStatus[],
  marketplaceProducts: MarketplaceProducts,
  dispatch,
  history
) => {
  return accountsMarketplacesColumnNames.map((column) => {
    if (column.id === 'mappingStatus') {
      return {
        ...column,
        Cell: ({ value }) => {
          return value.map(({ status }, index) => {
            const statusDisplayName = accountsMarketplacesStatuses.find(
              ({ code }) => code === status
            );
            return (
              <S.TableCellGroupItem key={index}>
                <div>
                  <S.StatusCircle status={status} />
                  <span>{statusDisplayName?.label}</span>
                </div>
              </S.TableCellGroupItem>
            );
          });
        },
      };
    }
    if (column.accessor === 'productCode') {
      return {
        ...column,
        Cell: ({ value, row }) => {
          const { provider, is3pic } = row.original;
          if (is3pic) {
            return getCellWithTooltip('3P1C');
          } else {
            const product = marketplaceProducts[provider].find(
              ({ productCode }) => productCode === value
            );
            return getCellWithTooltip(product?.displayName || value);
          }
        },
      };
    }
    if (column.id === 'actions') {
      return {
        ...column,
        Cell: ({ row }) => {
          const { customerRefId, provider, productCode } = row.original; // Access row data
          return (
            <Button
              variant="link"
              onClick={() => {
                dispatch({
                  type: 'setAccountMarketplaceDetailsFilterItems',
                  payload: { marketplaceId: customerRefId, marketplace: provider },
                });
                // Navigate to the marketplace details page
                history.push(`/accountsMarketplaces/${customerRefId}/view?marketplace=${provider}&productCode=${productCode}`);
              }}
              title="Show"
            >
              <ShowIcon />
              <span>View</span>
            </Button>
          );
        },
      };
    }
    return column;
  });
};
