import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as S from './AccountMarketplaceDetailsPage.styles';
import { useParams, useLocation } from 'react-router-dom';
import { getAccountMarketplaceDetails } from './AccountMarketplaceDetails.api';
import { StoreInterface } from '../../../interfaces/storeInterfaces';
import AccountMarketplaceDetailsPageTitle from './AccountMarketplaceDetailsPageTitle';
import AccountMarketplaceDetailsPageInfoSection from './AccountMarketplaceDetailsPageInfoSection';
import AccountMarketplaceDetailsPageSmAccountsSection from './AccountMarketplaceDetailsPageSmAccountsSection';
import { getAccountsMarketplacesConfiguration } from '../AccountsMarketplaces.api';

const AccountMarketplaceDetailsPage: React.FC = () => {
  const dispatch = useDispatch();
  const { id: marketplaceId } = useParams<{ id: string }>();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const marketplace = searchParams.get('marketplace') as string;
  const productCode = searchParams.get('productCode') as string;

  const isLoading = useSelector(
    (state: StoreInterface) => state.accountMarketplaceDetailsPage.loadingAccountMarketplaceDetails
  );

  useEffect(() => {
    const callFun = async () => {
      await getAccountMarketplaceDetails(marketplaceId, marketplace, productCode, dispatch);
      getAccountsMarketplacesConfiguration(dispatch);
    };

    callFun();
  }, []); // eslint-disable-line

  if (isLoading) return <S.LoadingWrapper>Loading... Please wait.</S.LoadingWrapper>;

  return (
    <>
      <AccountMarketplaceDetailsPageTitle />
      <AccountMarketplaceDetailsPageInfoSection />
      <AccountMarketplaceDetailsPageSmAccountsSection />
    </>
  );
};

export default AccountMarketplaceDetailsPage;
